body {
  font-family: "Roboto";
}
.alert-urban {
  background-color: #fdc41a !important;
  border-color: #e9b61d !important;
  color: #000 !important;
}
.sindatos {
  text-align: center;
  background: #000000;
  padding: 1em;
  border: 1px solid #fdc419;
  border-radius: 0.5em;
  margin: 1em 0;
}
.Degradado-bg {
  width: 100%;
  min-height: 100vh;

  background: -moz-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.92) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb000000', endColorstr='#eb000000',GradientType=1 );

  color: #fff;
}
.btn-urban {
  color: #000 !important;
  background-color: #fdc41a !important;
}
