/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/roboto-v20-latin-300.bda729db.eot); /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"),
    url(/static/media/roboto-v20-latin-300.bda729db.eot?#iefix) format("embedded-opentype"),
     url(/static/media/roboto-v20-latin-300.ef7c6637.woff2) format("woff2"),
     url(/static/media/roboto-v20-latin-300.b00849e0.woff)
      format("woff"),
     url(/static/media/roboto-v20-latin-300.806854d4.ttf)
      format("truetype"),
     url(/static/media/roboto-v20-latin-300.dd0bea1f.svg#Roboto)
      format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/roboto-v20-latin-regular.4be1a572.eot); /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"),
    url(/static/media/roboto-v20-latin-regular.4be1a572.eot?#iefix)
      format("embedded-opentype"),
     url(/static/media/roboto-v20-latin-regular.479970ff.woff2) format("woff2"),
     url(/static/media/roboto-v20-latin-regular.60fa3c06.woff)
      format("woff"),
     url(/static/media/roboto-v20-latin-regular.329ae1c3.ttf)
      format("truetype"),
    
      url(/static/media/roboto-v20-latin-regular.8681f434.svg#Roboto) format("svg"); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/roboto-v20-latin-700.4570b93a.eot); /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url(/static/media/roboto-v20-latin-700.4570b93a.eot?#iefix) format("embedded-opentype"),
     url(/static/media/roboto-v20-latin-700.2735a3a6.woff2) format("woff2"),
     url(/static/media/roboto-v20-latin-700.adcde98f.woff)
      format("woff"),
     url(/static/media/roboto-v20-latin-700.96559ffb.ttf)
      format("truetype"),
     url(/static/media/roboto-v20-latin-700.57888be7.svg#Roboto)
      format("svg"); /* Legacy iOS */
}

body {
  font-family: "Roboto";
}
.alert-urban {
  background-color: #fdc41a !important;
  border-color: #e9b61d !important;
  color: #000 !important;
}
.sindatos {
  text-align: center;
  background: #000000;
  padding: 1em;
  border: 1px solid #fdc419;
  border-radius: 0.5em;
  margin: 1em 0;
}
.Degradado-bg {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.92) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb000000', endColorstr='#eb000000',GradientType=1 );

  color: #fff;
}
.btn-urban {
  color: #000 !important;
  background-color: #fdc41a !important;
}

.Mitfullsize {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background: black;
  width: 100%;
  height: 100vh;
  padding: 1em;
  box-sizing: border-box;
  color: white;
  text-align: center;
  overflow-y:scroll;
  overflow-x:hidden;
}
.Mitfullsize .heading {
  margin-bottom: 1em;
}
.Mitfullsize img {
  width: 100%;
}

.Mitfullsize .btn {
  margin: 1em 0;
  font-size: 0.8em;
  margin-bottom: 1em;
}
.Mitfullsize .container {
  height: 100%;
}
.Mitfullsize .centrado {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-flow: column;
  height: calc(70vh);
  justify-content: center;
}
.Mitfullsize .bg {
  background-image: url(/static/media/bg.2b054996.png);
  background-size: cover;
  pointer-events: none;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
@media only screen and (max-device-width: 640px) and (orientation: landscape) {
  .Mitfullsize h2 {
    font-size: 1em;
  }
  .Mitfullsize .btn {
    margin: 0.1rem 0;
  }
}
.Mitfullsize .avisoPaper {
  border: 1px solid yellow;
  border-radius: 10px;
  color: #FFC000;
  font-weight: 300;
  font-size: x-large;
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 1em;
}
.Mitfullsize .paperDescription {
  text-align: justify;
}
