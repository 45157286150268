.Mitfullsize {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background: black;
  width: 100%;
  height: 100vh;
  padding: 1em;
  box-sizing: border-box;
  color: white;
  text-align: center;
  overflow-y:scroll;
  overflow-x:hidden;
}
.Mitfullsize .heading {
  margin-bottom: 1em;
}
.Mitfullsize img {
  width: 100%;
}

.Mitfullsize .btn {
  margin: 1em 0;
  font-size: 0.8em;
  margin-bottom: 1em;
}
.Mitfullsize .container {
  height: 100%;
}
.Mitfullsize .centrado {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-flow: column;
  height: calc(70vh);
  justify-content: center;
}
.Mitfullsize .bg {
  background-image: url("./bg.png");
  background-size: cover;
  pointer-events: none;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
@media only screen and (max-device-width: 640px) and (orientation: landscape) {
  .Mitfullsize h2 {
    font-size: 1em;
  }
  .Mitfullsize .btn {
    margin: 0.1rem 0;
  }
}
.Mitfullsize .avisoPaper {
  border: 1px solid yellow;
  border-radius: 10px;
  color: #FFC000;
  font-weight: 300;
  font-size: x-large;
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 1em;
}
.Mitfullsize .paperDescription {
  text-align: justify;
}